import React, { useState } from "react"

import styles from "./hero.module.css"
import classNames from "classnames"
import { Link } from "gatsby"
import ButtonRounded from "../../ButtonRounded"
import CornerContainer from "../../CornerContainer/CornerContainer"
import roastImg from "../../../images/roast.svg"

const HowItWorks = () => {
  const [videoScaled, setVideoScaled] = useState(false)

  return (
    <CornerContainer backgroundColor="colorLightGrey">
      <div className={styles.howItWorks}>
        <div className={styles.coreContent}>
          <h2 className={styles.titleHow}>
            Why Choose Undefined Over Offshore?
            {/* <span> A simple 4 step process to a better digital product</span> */}
          </h2>
          <div className={styles.howList}>
            <div>
              <img src={roastImg} />
            </div>
            <div className={styles.howListItems}>
              <h3>
                <span>1</span> Better communication
                <p>
                  With offshore teams, language and cultural barriers can hinder
                  collaboration. We're a UK-based team who speaks your language,
                  understands your users, and communicates clearly.
                </p>
              </h3>
              <h3>
                <span>2</span> Faster results
                <p>
                  Offshore teams often underestimate effort leading to delays.
                  Our experienced team scopes projects accurately and delivers
                  MVPs rapidly so you get to market faster.
                </p>
              </h3>
              <h3>
                <span>3</span> Higher quality
                <p>
                  Offshore teams tend to focus on output over outcomes. We take
                  a user-centric approach, challenging assumptions to avoid
                  confusing user experiences.
                </p>
              </h3>
              <h3>
                <span>4</span> More transparency
                <p>
                  Offshore providers lure you in with low rates then pile on
                  hidden costs. We provide fixed-price packages so you know what
                  you're getting.
                </p>
              </h3>
              <h3>
                <span>5</span> Added value
                <p>
                  Offshore teams just execute. We provide strategic guidance to
                  ensure you build products that deliver true value.
                </p>
              </h3>
              <h3>
                <span>6</span> Trusted expertise
                <p>
                  We've partnered with leading agencies and brands to
                  successfully deliver a wide array of digital products. You're
                  in safe hands with us.
                </p>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </CornerContainer>
  )
}

export default HowItWorks
