import React, { useState } from "react"

import takeplace from "../../../../images/work/takeplacelogo.png"
import macmillan from "../../../../images/work/macmillan.png"
import life from "../../../../images/work/life.png"
import ferly from "../../../../images/work/ferly.png"
import ogilvy from "../../../../images/work/ogilvy.png"

import bolt from "../../../../images/bolt.png"
import quote from "../../../../images/quote.png"
import {
  CheckCircledIcon,
  KeyboardIcon,
  TransformIcon,
  Cross1Icon,
} from "@radix-ui/react-icons"

import styles from "./ourPrices.module.css"
import CornerContainer from "../../../CornerContainer/CornerContainer"
import { Link } from "gatsby"
import ReactModal from "react-modal"

const OurApproach = (
  {
    // content: {
    //   title,
    //   subtitle,
    //   header,
    //   gridItems: {
    //     item1: { title, subtitle },
    //   },
    // },
  }
) => {
  const [plan, setPlan] = useState(null)

  const closeModal = () => setPlan(null)
  return (
    <>
      <CornerContainer>
        <div className={styles.container}>
          <div className={styles.button}>
            <div className={styles.boltCircle}>
              <img src={bolt} />
            </div>
            <div>Our Packages</div>
          </div>
          <div className={styles.subtext}>
            We offer fixed-price packages tailored to your needs
          </div>
          <div className={styles.description}>Packages Built for You</div>
          <div className={styles.itemContainer}>
            {/* Validate Ideas */}
            <div className={styles.gridItem}>
              {/* <div className={styles.itemTitle}>Get a quick start</div> */}
              <div className={styles.itemSubtitle}>
                Product
                <br /> Audit{" "}
                <p>
                  £4,000
                  <span className={styles.itemSubtitleSmall}>/one-off</span>
                </p>
              </div>
              {/* <div className={styles.itemDescription}>
                Not sure where to start? Our team of world-class designers &
                developers can help you find some quick wins.
              </div> */}

              <ul className={styles.salesPoints}>
                <li>
                  <CheckCircledIcon />{" "}
                  <p>Heuristic review of an existing product</p>
                </li>
                <li>
                  <CheckCircledIcon />
                  <p>Analysis of UI, UX, performance</p>
                </li>
                <li>
                  <CheckCircledIcon />
                  <p>Prioritised recommendations</p>
                </li>
                <li>
                  <CheckCircledIcon />
                  <p>Presentation of findings</p>
                </li>
              </ul>

              <button
                onClick={() => setPlan("app audit")}
                className={styles.btn}
              >
                Get in touch
              </button>
            </div>
            <div className={styles.gridItem}>
              {/* <div className={styles.itemTitle}>Get a quick start</div> */}
              <div className={styles.itemSubtitle}>
                Product Workshop{" "}
                <p>
                  £6,000
                  <span className={styles.itemSubtitleSmall}>/one-off</span>
                </p>
              </div>
              {/* <div className={styles.itemDescription}>
                A 5 day UX Sprint, the fastest way to execute. No internal
                meetings. No long lunches. Just full focus on taking your
                project to the next level. Improving an existing product,
                website or mobile app.
              </div> */}

              <ul className={styles.salesPoints}>
                <li>
                  <CheckCircledIcon />{" "}
                  <p>Align stakeholders on product vision</p>
                </li>

                <li>
                  <CheckCircledIcon /> <p>User journey mapping</p>
                </li>
                <li>
                  <CheckCircledIcon /> <p>Ideation sessions</p>
                </li>

                <li>
                  <CheckCircledIcon />
                  <p>Feature prioritisation</p>
                </li>
                <li>
                  <CheckCircledIcon /> <p>Prototype key screens</p>
                </li>
              </ul>

              <button
                onClick={() => setPlan("product workshop")}
                className={styles.btn}
              >
                Get started today
              </button>
            </div>
            <div className={styles.gridItem}>
              {/* <div className={styles.itemTitle}>Get a quick start</div> */}
              <div className={styles.itemSubtitle}>
                Takeover Support{" "}
                <p>
                  £9,000<span className={styles.itemSubtitleSmall}>/month</span>
                </p>
              </div>
              {/* <div className={styles.itemDescription}>
                Stop assuming and start validating. Prototype early to validate
                technical assumptions and remove risks.
              </div> */}

              <ul className={styles.salesPoints}>
                <li>
                  <CheckCircledIcon /> <p>Take over as primary support team</p>
                </li>
                <li>
                  <CheckCircledIcon /> <p>Triage and prioritise issues</p>
                </li>

                <li>
                  <CheckCircledIcon /> <p>Implement fixes and optimisations</p>
                </li>
                <li>
                  <CheckCircledIcon /> <p>Monitor performance and stability</p>
                </li>
                <li>
                  <CheckCircledIcon /> <p>Deliver enhancements</p>
                </li>
              </ul>

              <button
                onClick={() => setPlan("takeover")}
                className={styles.btn}
              >
                Get started today
              </button>
            </div>

            <div className={styles.gridItem}>
              {/* <div className={styles.itemTitle}>Get a quick start</div> */}
              <div className={styles.itemSubtitle}>
                Need a<br />
                custom Solution?
                {/* <p>
                  £9,000<span className={styles.itemSubtitleSmall}>/month</span>
                </p> */}
              </div>
              {/* <div className={styles.itemDescription}>
                Stop assuming and start validating. Prototype early to validate
                technical assumptions and remove risks.
              </div> */}

              <ul className={styles.salesPoints}>
                <li>
                  <CheckCircledIcon /> <p>Experts in Product Development</p>
                </li>
                <li>
                  <CheckCircledIcon />{" "}
                  <p>Rapid Prototyping to pitch concepts</p>
                </li>
                <li>
                  <CheckCircledIcon /> <p>Interactive Experiences</p>
                </li>
              </ul>

              <button onClick={() => setPlan("bespoke")} className={styles.btn}>
                Reach out now
              </button>
            </div>

            {/* <div>
              Need a custom solution? Get in touch{" "}
              <a style={{ textDecoration: "underline" }} href="/contact">
                here
              </a>
            </div> */}
            {/* Full Dev */}
            {/* D2C */}
            {/* <Link to="/services" className={styles.gridItem}>
            <div className={styles.itemTitle}>Upgrade your D2C presence</div>
            <div className={styles.itemSubtitle}>Sites that convert</div>
            <div className={styles.itemDescription}>
              With years of experience as a Shopify partner, we’re no stranger
              to the world of E-commerce. By hyper-charging this experience with
              a Headless approach, we aim to deliver performant experiences that
              your customers will love.
            </div>
            <div className={styles.itemImage}>
              <img className={styles.image3a} src={punchyApp} alt="punchyapp" />
              <img className={styles.image3b} src={punchyWeb} alt="punchyweb" />
            </div>
          </Link> */}
            <div className={styles.quoteContainer}>
              <img className={styles.quoteMark} src={quote} alt="quote" />

              <figure className={styles.figure}>
                <blockquote className={styles.quote}>
                  This is Undefined delivered on time and was a joy to work with
                  through all the typical startup iterations
                </blockquote>
                <figcaption className={styles.smallText}>
                  Matt Roele, CTO @ Topia
                </figcaption>
              </figure>
            </div>
            <div className={styles.clientLogosContainer}>
              <div className={styles.smallText}>Also trusted by</div>
              <img className={styles.logo} src={ogilvy} alt="ogilvy" />
              <img className={styles.logo} src={takeplace} alt="takeplace" />
              <img className={styles.logo} src={macmillan} alt="macmillan" />
              <img className={styles.logo} src={ferly} alt="ferly" />
              <img className={styles.logo} src={life} alt="life" />
            </div>
            {/* Work With us */}
          </div>
        </div>
      </CornerContainer>
      <CornerContainer backgroundColor="colorOffWhite" overlapSpacing>
        <div className={styles.finalGridItem} key="gridItemExtra">
          <div className={styles.itemExtraTitle}>
            Get in touch today to discuss how we can partner to build your next
            product.
          </div>

          {/* <p className={styles.itemExtraText}>
              Depending on the state of your product, some concepts might
              benefit from additional services we have to offer. These add-ons
              are a part of a larger scope post initial audit and can help make
              the suggestions a reality
            </p> */}

          <form
            name="contact"
            id="free-audit-form"
            method="post"
            data-netlify="true"
            hidden="true"
            className={styles.contactForm}
            data-netlify-honeypot="bot-field"
          >
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="audit" />

            {/* <label for="name"> Tell us a bit about yourself</label> */}
            <div className={styles.inputBoxes}>
              <input
                type="text"
                name="name"
                id="name"
                placeholder="Name"
                required
              />

              <input type="email" name="email" placeholder="Email" required />
            </div>

            <textarea
              className={styles.lastInput}
              type="text"
              name="app_url"
              id="app_url"
              placeholder="Explain a bit about your project. Ex. App name or url"
              rows="10"
            />
            <div>
              <button className={styles.btn} type="submit">
                Send
              </button>
            </div>
          </form>
        </div>
      </CornerContainer>
      <ReactModal
        isOpen={!!plan}
        onRequestClose={closeModal}
        className={styles.modalForm}
      >
        <div className={styles.modalFormContainer}>
          <button onClick={closeModal} className={styles.closeBtn}>
            <Cross1Icon></Cross1Icon>
          </button>
          <div className={styles.itemExtraTitle}>Get in touch</div>

          {/* <p className={styles.itemExtraText}>
              Depending on the state of your product, some concepts might
              benefit from additional services we have to offer. These add-ons
              are a part of a larger scope post initial audit and can help make
              the suggestions a reality
            </p> */}

          <form
            name="contact-modal"
            id="free-audit-form-modal"
            method="post"
            data-netlify="true"
            hidden="true"
            className={styles.contactForm}
            data-netlify-honeypot="bot-field"
          >
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="audit-modal" />

            {/* <label for="name"> Tell us a bit about yourself</label> */}
            <div className={styles.inputBoxes}>
              <input
                type="text"
                name="name"
                id="name"
                placeholder="Name"
                required
              />

              <input type="email" name="email" placeholder="Email" required />
            </div>

            <div className={styles.inputBoxes}>
              <div className={styles.inputWithLabel}>
                <label>Choose Plan</label>
                <select name="package" required value={plan}>
                  <option value="app audit">Product Audit</option>
                  <option value="product workshop">Product Workshop</option>
                  <option value="takeover">takeover support</option>
                  <option value="bespoke">bespoke</option>
                </select>
              </div>
            </div>

            <div className={styles.inputBoxes}>
              <textarea
                className={styles.lastInput}
                type="text"
                name="app_url"
                id="app_url"
                placeholder="Explain a bit about your project. Ex. App name or url"
                rows="10"
                minLength={100}
              />
            </div>
            <div>
              <button className={styles.btn} type="submit">
                Send
              </button>
            </div>
          </form>
        </div>
      </ReactModal>
    </>
  )
}

export default OurApproach
