import React, { useState } from "react"
import Layout from "../components/layout.js"
import Clients from "../components/clients/clients.js"
import MakeItReal from "../components/MakeItReal.js"
import OurProcess from "../components/OurProcess.js"
import AnimatedText from "../components/AnimatedText.js"
import StartAProjectBig from "../components/StartAProjectBig/StartAProjectBig.js"
import SEO from "../components/seo"
import Hero from "../components/Hero/AgencyPartners"
import HowItWorks from "../components/Hero/AgencyPartners/howItWorks"
import OurPrices from "../components/Hero/AgencyPartners/OurPrices"
import OurTools from "../components/OurTools"
import ProjectBanner from "../components/ProjectBanner"
import { ourTools } from "../content/homepage/ourToolsContent"
import { projectBanner } from "../content/homepage/projectBannerContent"
import styles from "./homepage.module.css"
import classNames from "classnames/bind"
import simpleScroll from "simple-scroll"
import ideas from "../images/ideas.svg"
import build from "../images/build.svg"
import launch from "../images/launch.svg"
import innovate from "../images/innovate.svg"

// if (!isServer) {
// 	require('viewport-units-buggyfill').init();
// }

const Index = () => {
  const [isMobile, setIsMobile] = useState(false)
  const [animationComplete, setAnimationComplete] = useState(false)
  const [progress, setProgress] = useState(1)
  const [scrollOpacity, setScrollOpacity] = useState(1)

  const logo = () => {
    return (
      <h1 className={styles.logo}>
        <span className={styles.logoDetail}>.</span>
      </h1>
    )
  }

  return (
    <Layout noFade displayBanner={true}>
      <SEO
        title="Rapid Product Development"
        description="Losing users to poor User Experience? Our team can help you improve
          your app or SaaS web app and retain them. We'll identify the problems
          with your flows and make sure your product is easy to use."
        image="/images/undefined-audit-share.jpg"
      />
      <Hero />
      <HowItWorks />
      <OurPrices />
      {/* <OurTools content={ourTools} /> */}
      {/* <StartAProjectBig /> */}
      {/* <ProjectBanner content={projectBanner} /> */}
    </Layout>
  )
}

export default Index
