import React, { useState } from "react"

import styles from "./hero.module.css"
import classNames from "classnames"
import { Link } from "gatsby"
import ButtonRounded from "../../ButtonRounded"

const Hero = () => {
  const [videoScaled, setVideoScaled] = useState(false)

  return (
    <div
      className={classNames(styles.container, {
        [styles.videoScaled]: videoScaled,
      })}
    >
      <div className={styles.coreContent}>
        <div className={styles.title}>
          <span className={styles.titleBold}>A Smarter Alternative</span>
          <br /> to Offshore Development
        </div>
        <div className={styles.subtitle}>
          We understand the temptation to go offshore. The promise of lower
          rates is appealing. But is it worth the risk? We provide a smarter
          solution. Our design-led approach delivers high-quality products
          without the pitfalls of offshore outsourcing.
        </div>
        <div className={styles.actions}>
          <div className={styles.buttonContainer}>
            <ButtonRounded
              isExternal
              to="https://calendly.com/undefined-siebe/discovery-call"
              className={styles.button}
              text="Book a discovery call"
            />
            {/* <a href="#free-audit-form">
              <div className={styles.bookLink}>// request a free audit</div>
            </a> */}
          </div>
        </div>
        <div
          className={classNames(styles.videoContainer)}
          onClick={() => setVideoScaled(true)}
          onMouseLeave={() => {
            setVideoScaled(false)
          }}
        >
          <video
            src="/images/undefined-demo.mp4"
            className={styles.video}
            autoPlay
            loop
            preload="metadata"
            muted={!videoScaled}
            playsinline
          />
        </div>
        <div className={styles.clutchReview}></div>
      </div>
    </div>
  )
}

export default Hero
